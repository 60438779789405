.TableWidget tbody tr:hover td {
    background-color: #504f4f !important;
    cursor: pointer;
}

.TableWidget tr {
    background-color: #252627 !important;
    text-align: center !important;

    color: black;
}

.TableWidget th {
    background-color: #163046 !important;
    color: white !important;
    text-align: center !important;
    /* border: 1px solid #636161; */
    font-weight: bold;
    font-size: 16px;
}

.TableWidget tbody tr:hover td {
    background-color: #504f4f !important;
    cursor: pointer;
}

.TableWidget .ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    padding: 16px 16px;
    overflow-wrap: break-word;
}

.TableWidget tbody td {
    background-color: #c4c4c4 !important;
    font-size: 16px;
    color: "black";
}

.TableWidget .ant-table-placeholder {
    background-color: #595959 !important;
}

.TableWidget ant-table-thead {
    background-color: grey !important;
    cursor: pointer;
}

.antdcollapsed-button {
    position: absolute;
    inset-block-start: 18px;
    z-index: 101;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 40px;
    inset-inline-end: -13px;
    transition: transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgb(22, 48, 70);
    background-color: #ffffff;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(25, 15, 15, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}

.debugMode {
    display: flex;
    text-align: center;
    margin: 8px;
    padding: 2px;
    border: 4px solid #d0d0d0;
    border-radius: 32px;
    justify-content: center;
    background-color: #bdbdbd;
}