
.typing-indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0.3rem;
    border-radius: 1rem;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
    width: 40px;
    text-align: left;
}

.typing-indicator-container.visible {
    opacity: 1;
}

.typing-indicator-container.hidden {
    opacity: 0;
}

.typing-indicator-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #0b0a0a;
    margin: 0 1px;
    animation: typing-indicator-bounce 1.2s infinite ease-in-out;
}

.typing-indicator-dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator-dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes typing-indicator-bounce {
    0%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-5px);
    }
}
